//
// ------------------ //
// Raw Colors         //
// ------------------ //

// Basic Colors
$color-white: #fffdfc;
$color-black: #1c1102;

// Theme Colors
$color-green-dark: #49634b;
$color-gold-dark: #a47a28;

//
// ------------------ //
// Viaventuro Colors  //
// ------------------ //
$color-primary: $color-green-dark;
$color-secondary: $color-gold-dark;
$color-light: $color-white;
$color-dark: $color-black;

//
// ------------------ //
// Functional Colors  //
// ------------------ //
$color-bg: $color-light;
$color-text: $color-dark;
$color-shadow: rgba(16, 4, 45, 0.2);
$color-shadow-dark: rgba(26, 3, 41, 0.7);
